.DayPicker {
    z-index: 1;
    position: absolute;
}

/*.CalendarDay__selected_span {*/
/*background: lightskyblue;*/
/*color: white;*/
/*border: 1px solid blue;*/
/*}*/

/*.CalendarDay__selected {*/
/*background: lightskyblue;*/
/*color: white;*/
/*}*/

/*.CalendarDay__selected:hover {*/
/*background: orange;*/
/*color: white;*/
/*}*/

/*.CalendarDay__hovered_span:hover,*/
/*.CalendarDay__hovered_span {*/
/*background: brown;*/
/*}*/
