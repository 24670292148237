body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
#table table {width: 70%}
#table table thead {border-collapse: collapse; text-align: center}
#table table tr td {border-collapse: collapse; text-align: center}
#table thead {background-color: #c9c6b4}
#table thead tr td  input {background-color: #c9c6b4; border: none}
#table td{padding:5px;}

#real {
    width:750px;
    background-color: #fff;
    padding:20px;
    font-size:17px;
    font-family: Malgun Gothic;

}

#realTable {
    border: 1px solid darkgrey;
    margin-bottom: 20px;
}

#realTable td{
    border: 1px solid darkgrey;
}

#realBody td{
    border-color: darkgrey;
    width: 120px;
    height: 40px;
    font-size: 20px;
}

#realBody{
    text-align: center;
}

#realBody tr:nth-child(1) {
    background-color: #c9c6b4;
}
